.mainBoxProgress {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workflow-progress-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Ermöglicht das Umbruchverhalten */
  justify-content: space-between; /* Gleichmäßiger Abstand zwischen den Karten */
  gap: 20px; /* Abstand zwischen den Karten */
}

.workflow-card {
  flex: 1 1 calc(50% - 20px); /* Zwei Karten pro Zeile mit Abstand berücksichtigt */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px; /* Reduzierter Abstand */
  box-shadow: 2px 2px 12px #aaa;
  margin: 0;
}

.workflow-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px; /* Reduzierter Abstand */
  border-radius: 6px;
  font-size: 1.1em; /* Reduzierte Schriftgröße */
  margin-bottom: 10px; /* Abstand zwischen Header und Gruppen-Container */
}

.progress-barBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px; /* Abstand zwischen Fortschritt-Text und Balken */
  font-size: 0.9em; /* Reduzierte Schriftgröße */
  text-align: center;
  width: 40%;
}

.progress-bar {
  width: 100%;
  height: 25px;
  display: flex;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  background-color: #e0e0e0;
}

.groups-container {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Reduzierter Abstand */
}

.group-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.statusBox {
  width: 30px; /* Reduzierte Größe */
  height: 30px;
  border-radius: 5px;
}

.progress {
  height: 100%;
}

.planned {
  width: 33%; /* Beispielwert */
  background-color: #e0e0e0;
}

.active {
  width: 33%; /* Beispielwert */
  background-color: orange;
}

.done {
  width: 34%; /* Beispielwert */
  background-color: green;
}
