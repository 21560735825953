.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 1600px;
  background-color: rgb(238, 238, 238);
}

.App-Menu {
  width: 10%;
  height: 100%;
}
.App-Main {
  width: 90%;
  flex-grow: 1;
  display: block;
  padding: 20px;
}

.App-Share {
  width: 100vw;
  height: 100vh;
}
