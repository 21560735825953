.afterSaleForm-container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.afterSaleForm-containerHeader {
  background-color: var(--main-color);
  color: white;
  padding: 20px 0px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.afterSaleForm-section {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.afterSaleForm-section h2,
.afterSaleForm-section h3 {
  margin-bottom: 15px;
  color: var(--main-color);
}

.afterSaleForm-select,
input,
select,
textarea {
  width: 100%;
  padding: var(--input-padding);
  border: 1px solid var(--input-border);
  border-radius: 5px;
  background-color: var(--input-bg);
  transition: border-color 0.3s;
}

.afterSaleForm-select:focus,
input:focus,
select:focus {
  border-color: var(--input-focus-border);
  outline: none;
}

label {
  margin-bottom: 8px;
  font-weight: 500;
}

button {
  padding: var(--input-padding);
  border: none;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Für Responsivität auf kleineren Bildschirmen */
@media (max-width: 650px) {
  .afterSaleForm-container {
    width: 90%;
  }
}

.formQestion {
  margin: 15px 0px;
}

.disabledBtn {
  pointer-events: none; /* Verhindert Klick-Events */
  opacity: 0.5; /* Macht den Knopf halbtransparent */
}
