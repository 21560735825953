.ChatbotHeader {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  height: 60px;
  background-color: rgb(230, 230, 230);
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  padding: 10px;
  border-bottom: 1px solid var(--main-color);
}

.ChatbotHeader input:focus {
  outline: 2px var(--main-color) solid;
}
.ChatbotHeader input {
  width: 400px;
  border-radius: 5px;
  border: none;
  outline: 1px gainsboro solid;
  height: 40px;
}

.nachrichtNode {
  width: 300px;
  border: 1px solid #ececec;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #dcf8c7;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.nachrichtNode .nodeSettings {
  background-color: #135a13;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
}
.nachrichtNode .nodeSettings .btnBox {
  background-color: #135a13;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
}

.nachrichtNode .nodeSettings .nameBox {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
}
.nachrichtNode .nodeButton {
  background-color: white;
  color: #135a13;
  height: 20px;
  width: 20px;
  font-size: 12px;
  padding: 5px;
}

.nachrichtNode .nachrichtHeader {
  border-bottom: 1px solid #135a13;
  color: #000000;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
}

.nachrichtNode .nachrichtBody {
  padding: 10px 15px;
  flex-grow: 1;
  overflow: auto;
  font-size: 14px;
  line-height: 1.4;
}

.nachrichtNode .nachrichtFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nachrichtNode .nachrichtButton {
  padding: 8px 15px;
  width: 100%;
  border: none;
  font-size: 14px;
  color: #070707;
  border-top: 1px solid #135a13;
  background-color: #dcf8c7;
  cursor: pointer;
  text-align: left;
  display: flex;
  gap: 10px;
  transition: background-color 0.3s;
}

.nachrichtNode .nachrichtButton:hover {
  background-color: #216621;
}

.whatsappMsgSelect {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #7eb37c;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, border 0.3s;
}

.whatsappMsgSelect:hover {
  background-color: #e0e0e0;
}

.whatsappMsgSelect:focus {
  border-color: #216621;
}

/* Optional: Ein Pfeil-Icon für das Dropdown-Menü */
.whatsappMsgSelect::-ms-expand {
  display: none; /* für IE11 */
}

.whatsappMsgSelect::after {
  content: "\25BC"; /* Dies ist ein Unicode-Pfeil nach unten */
  font-size: 10px;
  padding-left: 5px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Damit es nicht anklickbar ist */
  color: #7eb37c;
}

.chatbotSection {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 0.5px solid gray;
}

.chatbotFlowChart {
  height: 750px;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(220, 231, 216);
  border: 1px solid var(--main-color);
}

.ChatbotInformation {
  width: 700px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.chatbotSection input:focus {
  outline: 2px var(--main-color) solid;
}
.chatbotSection input {
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: 1px gainsboro solid;
  height: 30px;
}

.targetNachricht {
  border-top: 6px solid rgb(11, 155, 238);
  animation: breathe 1.5s infinite alternate;
}

.sourceNachricht {
  border-bottom: 3px solid rgb(3, 223, 3);
  animation: breatheColor 1.5s infinite alternate;
}

.sourceUnplugMessage {
  border-bottom: 3px solid rgb(247, 19, 2);
  animation: breatheColorRed 1.5s infinite alternate;
}

.targetUnplugMessage {
  border-top: 6px solid rgb(238, 45, 11);
  animation: breatheRed 1.5s infinite alternate;
}

/* --- Keyframes-Animationen --- */

@keyframes breatheColorRed {
  0%,
  100% {
    border-bottom: 3px solid rgb(255, 0, 0);
  }
  50% {
    border-bottom: 6px solid rgb(182, 4, 4);
  }
}

@keyframes breatheColor {
  0%,
  100% {
    border-bottom: 3px solid green;
  }
  50% {
    border-bottom: 6px solid rgb(13, 160, 13);
  }
}

@keyframes breatheRed {
  0%,
  100% {
    transform: scale(1);
    border-top: 6px solid rgb(250, 21, 5);
  }
  50% {
    transform: scale(1.02);
    border-top: 6px solid rgb(236, 15, 7);
  }
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
    border-top: 6px solid rgb(5, 250, 46);
  }
  50% {
    transform: scale(1.02);
    border-top: 6px solid rgb(8, 194, 39);
  }
}

.unsetVariable {
  border: 2px solid red;
}
