.timeTracking {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.timeTrackingHeader {
  width: 100%;
  height: 5%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  flex-direction: row;
  margin-bottom: 100px;
}
.timeTrackingBody {
  width: 100%;
  height: fit-content;
  padding: 10px;
}
.timeTrackingHeaderSelectCell {
  flex-grow: 1;
  max-width: 40%;
}

.fc .fc-header-toolbar .fc-button-active {
  background-color: var(--main-color);
  color: #ffffff;
}
.fc .fc-header-toolbar .fc-button-primary {
  background-color: var(--main-color);
  color: #ffffff;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--main-color-hover);
  color: #ffffff;
}
