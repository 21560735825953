.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  z-index: 1000; /* To ensure it's on top of other elements */
  -webkit-box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.4);
}

.notification.error {
  background-color: red;
}

.notification.good {
  background-color: green;
}
