.menu-container {
  width: calc(100%-1px);
  min-height: 100%;
  background-color: #ffffff;
  border-radius: 0px var(--main-radius) var(--main-radius) 0px;
  font-weight: 300;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.menu-logo {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: auto;
  font-weight: 700;
}
.userMenu {
  padding: 0px;
  margin: 0px;
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-color);
}

.menu-punkt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  transition: background-color 0.3s;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.menu-punkt-aktiv {
  color: var(--main-color);
}
.menu-punkt:hover {
  background-color: #f0f0f0;
  color: var(--main-color);
}

.menu-punkt-emoji {
  font-size: 1rem;
  margin-right: 10px;
  width: 30px;
}

.menu-punkt-text {
  font-size: 1rem;
}
