.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: #f0f0f0;
}

.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.login-title {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.login-header{
    font-weight: bold;
    font-size: 40px;
    color: var(--main-color);
}

.login-input {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.login-button {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
}

.login-button:hover {
    background-color: var(--main-color-hover);
}
