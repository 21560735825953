body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*--main-color: #5e2bff;*/
:root {
  --main-color: #5e2bff;
  --main-color-hover: rgb(92, 19, 226);

  --background-color: #e7e7e7;

  --container-color: #ffffff;
  --container-color-hover: rgb(238, 238, 238);

  --inactive-color: #c4c3c3;
  --font-color: black;
  --bright-font-color: #f3f3f3;

  --main-radius: 5px;
  --main-padding: 20px;

  --input-border: #ccc;
  --input-focus-border: #5e2bff;
  --input-bg: #fff;
  --input-padding: 10px 15px;
}
