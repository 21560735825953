.customerOverView {
  width: 100%;
  height: 100%;
}
.customerOverViewHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: var(--main-radius);
  min-width: 100%;
  width: fit-content;
  height: fit-content;
  color: white;
}

.customerOverViewBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  overflow: auto;
  padding: 10px;
  min-width: 100%;
  min-height: 100%;
  width: fit-content;
  height: fit-content;
}
.customerRow {
  padding: 5px 0px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  min-width: calc(100% - 20px);
  width: fit-content;
  min-height: 40px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}
.OverviewZelle {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}

.OverviewBreite-3 {
  width: 300px;
}

.customerDetailsBox {
  padding-bottom: 10px;
  margin: 0px 10px;
}

.customerDetailsBox-Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  height: fit-content;
  border-bottom: 1px solid gray;
  padding: 10px 10px;
}

.customerDetailsBox-Header-Buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.customerDetailsBox-Header-Buttons-Btn {
  width: fit-content;
  height: 30px;
  color: white;
  background-color: var(--main-color);
  padding: 5px 15px;
  border-radius: var(--main-radius);
}
.customerDetailsBox-Header-Buttons-Btn:hover {
  background-color: var(--main-color-hover);
  cursor: pointer;
}

.customerDetailsBox-Header-Kommunikation {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.modalStyle {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.modalHeader {
  width: 100%;
  height: 50px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  color: #ffffff;
  background-color: var(--main-color);
  flex-direction: row;
}
.closeModal {
  font-size: 30px;
  width: 40px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.closeModal:hover {
  background-color: rgb(60, 16, 102);
}

.modalName {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.modalBody {
  width: 100%;
  gap: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 30px;
}

.modern-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  transition: border-color 0.3s;
}

.modern-input:focus {
  border-color: var(
    --main-color
  ); /* Ein heller Blauton, kann nach Belieben geändert werden */
  outline: none;
}

.modalBody button {
  padding: 10px 15px;
  font-size: 16px;
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modalBody button:hover {
  background-color: var(--main-color-hover);
}

.shadow {
  position: fixed; /* Fixiert das Overlay über der gesamten Seite */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Das Overlay nimmt die volle Höhe und Breite des Viewports ein */
  background-color: rgba(0, 0, 0, 0.5); /* Schwarz mit 70% Deckkraft */
  z-index: 999; /* Hoher z-index, damit es über den meisten anderen Elementen liegt */
}

.workflowStartBtn {
  background-color: var(--main-color);
  width: 300px;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--main-radius);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.workflowStartBtn:hover {
  background-color: var(--main-color-hover);
  cursor: pointer;
}

.projectHead {
  display: flex;
  flex-direction: row;
}

.tag {
  width: 70%;
  height: 90%;
  border-radius: var(--main-radius);
  height: 30px;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textarea-modern {
  width: 100%; /* Nutzt die volle Breite des Containers */
  padding: 10px 15px; /* Innenabstand für bessere Lesbarkeit */
  font-size: 16px; /* Größere Schriftgröße */
  line-height: 1.5; /* Mehr Zeilenabstand für bessere Lesbarkeit */
  border: 1px solid #e0e0e0; /* Dezente Randfarbe */
  border-radius: 6px; /* Abgerundete Ecken */
  background-color: #f5f5f5; /* Leicht grauer Hintergrund */
  color: #333; /* Dunklere Schriftfarbe */
  resize: vertical; /* Erlaubt nur vertikales Skalieren */
  transition: border-color 0.3s, box-shadow 0.3s; /* Sanfter Übergangseffekt für Rand und Schatten */

  /* Fügt einen Schatten hinzu, wenn das Textfeld fokussiert ist */
  &:focus {
    border-color: var(
      --main-color,
      #3498db
    ); /* Nutzt die Hauptfarbe oder ein Standardblau, falls nicht definiert */
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.3); /* Ein sanfter Schatten für den Fokus-Effekt */
    outline: none; /* Entfernt den standardmäßigen Browser-Umriss */
  }

  /* Fügt einen Stil für das deaktivierte Textfeld hinzu */
  &:disabled {
    background-color: #e9e9e9; /* Etwas dunklerer Hintergrund für das deaktivierte Textfeld */
    color: #a0a0a0; /* Graue Schriftfarbe */
    cursor: not-allowed; /* Zeigt an, dass das Textfeld deaktiviert ist */
  }
}

.projectTable {
  width: 99%;
  margin: auto;
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: var(--main-radius);
}
.projectRow {
  border-radius: var(--main-radius);
  padding: 10px 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  margin: 10px 0px;
  background-color: #fff;
}
.splitRow {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tab-navigation {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  margin-right: 5px;
  border: none;
  border-bottom: 2px solid transparent;
  background: none;
  cursor: pointer;
  color: #333;
  transition: border-color 0.3s, color 0.3s;
}
.tab-button-active {
  border-bottom: 1px solid var(--main-color);
  color: var(--main-color);
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.project-dashboard {
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  flex-direction: column;
}

.time-container {
  width: 100%;
  margin-bottom: 10px;
}

.time-display {
  color: #333;
  margin-bottom: 5px;
}

.exceeded-time {
  color: red;
}

.time-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.filled-time-bar {
  height: 100%;
  background-color: #4caf50;
}

.cost-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cost-display {
  color: #333;
  display: flex;
  align-items: center;
}

.cost-icon {
  margin-right: 5px;
  font-size: 18px;
}
