.workFlowConfiguratorSection {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
}

.innerBoxPlanner {
  width: 100%;
  height: fit-content;
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.innerBoxPlanner h1 {
  margin: 0px;
  padding: 0px;
}

.RollenBoxContainer {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin: 10px 0px;
}

.rolleBox {
  min-width: 300px;
  flex-grow: 1;
  height: fit-content;
}

.rolleBox .rollenSelect {
}

.innerBoxPlanner .automationDesc {
  width: 100%;
  display: flex;
  height: fit-content;
  margin-top: 10px;
  border-radius: 5px;
  padding: 20px;
  background-color: #f0f0f0;
}

.plannerBox {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin: 10px 0px;
}

.mainModal {
  width: 750px;
  height: 80%;
  background-color: white;
  border: 1px solid var(--main-color);
  border-radius: var(--main-radius);
  z-index: 20000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 80px -1px #0000005d;
  box-shadow: 0px 0px 80px -1px #0000006b;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

/*InputFelder*/

/* WorkflowConfigurator.css */

.newElementsMainBox {
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.radioBox {
  display: flex;
  justify-content: start;
  margin-bottom: 15px;
}

.radioBox label {
  margin-right: 15px;
  font-size: 16px;
  color: #333;
}

.radioBox input[type="radio"] {
  margin-right: 5px;
}

.newElementBox {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.newElementBox > div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newElementBox input[type="text"] {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.newElementBox button {
  margin-top: 10px;
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.newElementBox button:hover {
  background-color: var(--main-color);
}

/* Additional styles for better positioning and spacing */
.itemInteractionArea {
  display: flex;
  align-items: center;
}

.deleteButton {
  margin-left: 10px; /* Space between text input and delete button */
}
.newElementBox > div {
  margin-bottom: 15px; /* Increased space between items */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start */
}
