.mainBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: var(--main-radius);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.addBtn {
  background-color: var(--main-color);
  width: 300px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--main-radius);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.addBtn:hover {
  background-color: var(--main-color-hover);
  cursor: pointer;
}

.mainBox::-webkit-scrollbar {
  width: 5px;
}

.mainBox::-webkit-scrollbar-track {
  background: #bda0f3;
  border-radius: 0px 5px 5px 5px;
}

.mainBox::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 5px 5px 5px 5px;
}

.mainBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}
