.tabs {
  display: flex;
  justify-content: space-between; /* Ändern zu space-between für gleichmäßigen Abstand */
  padding: 10px;
  background-color: var(--background-color);
}

.tabs a {
  flex: 1;
  text-align: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid blue;
  background-color: #bbbbbb;
  margin: 0 5px; /* Fügt horizontalen Abstand hinzu */
  box-sizing: border-box; /* Stellt sicher, dass Padding und Border innerhalb der Breite enthalten sind */
}

.tabs a:hover {
  background-color: #ffffff;
}

.tabs a.active {
  background-color: var(--main-color);
  color: whitesmoke;
  border-bottom: 2px solid blue;
}
