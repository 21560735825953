/*Header*/
.EditDataContainerHeader {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  border-radius: 4px 4px 0px 0px;
}

.EditDataContainerHeader .EditDataContainerHeader-views-contrainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-radius: var(--main-radius) var(--main-radius) 0px 0px;
}
.view-box {
  font-size: 1rem;
  padding: 10px;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
  background-color: rgba(187, 183, 194, 0.356);
}
.view-box:first-child {
  border-radius: 5px 5px 0px 0px;
}
.view-box:last-child {
  border-radius: 5px 5px 0px 0px;
}
.view-box:hover {
  background-color: var(--container-color-hover);
  cursor: pointer;
  color: var(--main-color);
}
.view-box-selected {
  color: #fff;
  font-weight: 700;
  background-color: var(--main-color);
}
.view-box-selected:hover {
  color: #fff;
  font-weight: 700;
  background-color: var(--main-color);
}
.EditDataContainerHeader .EditDataContainerHeader-filter-container {
  display: flex;
  background-color: var(--main-color);
  flex-direction: column;
  color: #fff;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 0px 0px 0px;
}
.header-info {
  width: 20%;
  text-align: left;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.filter-header-search-input {
  height: 100%;
  min-height: 25px;
  padding: 0 10px;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  border: 1px solid var(--container-color-hover);
}

.filter-header-search-input:focus {
  outline: 1px solid var(--main-color);
}

/*Body*/

.EditDataContainerBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 0px 0px 5px 5px;
}
/* Dünner und moderner Scrollbalken */
.EditDataContainerBody::-webkit-scrollbar {
  width: 5px;
}

.EditDataContainerBody::-webkit-scrollbar-track {
  background: #bda0f3;
  border-radius: 0px 0px 5px 5px;
}

.EditDataContainerBody::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 5px 5px 5px 5px;
}

.EditDataContainerBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.zeile {
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  width: fit-content;
  min-height: 40px;
  border-radius: 5px;
  height: fit-content;
  margin-bottom: 10px;
  align-items: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.zeileHeader {
  background: var(--main-color);
  color: white;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
.spaltenHeadersContainer {
  display: flex;
  height: 100%;
  flex-shrink: 0;
  flex-direction: row;
}

.EditDataZelle {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  border-left: 1px dashed rgb(60, 5, 161);
}
.zeileHeader .EditDataZelle {
  border-left: 1px dashed rgb(255, 255, 255);
}
/*Hilfsbreiten*/
.breite-0 {
  width: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--main-color);
}
.breite-0:hover {
  background-color: rgb(204, 204, 204);
  border-radius: 10px;
}
.breite-1 {
  width: 150px;
  flex-shrink: 0;
}
.breite-2 {
  width: 300px;
  flex-shrink: 0;
}
.breite-3 {
  width: 500px;
  flex-shrink: 0;
}

/*InputFelder*/
.EditDataZelle .selectTableEdit {
  width: 90%;
  height: 90%;
}
.EditDataZelle .selectTableEdit:focus {
  outline: 1px var(--main-color) solid;
}
.EditDataZelle input:focus {
  outline: 2px var(--main-color) solid;
}
.EditDataZelle input {
  width: 90%;
  border-radius: 5px;
  border: none;
  outline: 1px gainsboro solid;
  height: 90%;
}

.zeileWhiteBoardEdit {
  padding: 5px 0px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  min-width: 100%;
  width: fit-content;
  min-height: 40px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.zeileWhiteBoardEdit-Header {
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  width: fit-content;
  min-height: 40px;
  border-radius: 5px;
  height: 50px;
}
.FlowChartBox {
  width: 100%;
  padding: 10px;
  height: 50vh;
}

.InputTextarea {
  width: 100%;
  height: 100px;
  resize: vertical; /* Erlaubt nur das Skalieren in der Y-Richtung */
  border: 1px solid #ccc; /* Ein einfacher Rand */
  padding: 10px; /* Etwas Innenabstand */
  border-radius: 5px; /* Abgerundete Ecken für ein modernes Aussehen */
  font-family: Arial, sans-serif; /* Eine moderne Schriftart */
  font-size: 14px; /* Schriftgröße */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Ein innerer Schatten für Tiefe */
  outline: none; /* Entfernt den Umriss beim Fokussieren */
  transition: border-color 0.3s; /* Eine sanfte Übergangseffekt */
}

.InputTextarea:focus {
  border-color: var(--main-color); /* Ändert die Randfarbe beim Fokussieren */
}

.durationBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.durationBoxBox {
  width: 40%;
}

.durationBox input {
  width: 80%;
  margin-right: 3px;
}
