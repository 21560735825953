.flowchartMainBox {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border: 1px solid var(--main-color);
  border-radius: var(--main-radius);
  display: flex;
  flex-direction: column;
}
.flowchartMainBox-Header {
  height: 50px;
  width: 100%;
  border-radius: var(--main-radius) var(--main-radius) 0px 0px;
  background-color: var(--main-color);
  color: white;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
}
.flowchartMainBox-HeaderInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.flowchartMainBox-HeaderInfo input {
  height: 30px;
  border-radius: var(--main-radius);
  border: none;
}
.flowchartMainBox-HeaderInfo input:disabled {
  background-color: rgb(222, 205, 255);
  cursor: not-allowed;
}

.flowchartMainBox-Body {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: row;
}
/*FlowchartBox*/
.FlowBox {
  display: flex;
  width: calc(100% - 4px);

  height: 50vh;
  border: 2px solid var(--main-color);
  align-items: stretch; /* sorgt dafür, dass alle Kinder (Flowchart und Sidebar) die gleiche Höhe haben */
}

/*Sidebar*/
.sidebar {
  flex: 0 0 20vh; /* gibt der Sidebar eine Breite von 20vh und verhindert das Schrumpfen und Wachsen */
  background-color: #f8f9fa;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  overflow-y: auto;
  max-height: calc(100%);
  border-right: 1px solid var(--main-color);
  border-radius: 5px 0px 0px 5px;
}
.sidebarHeader {
  height: 70px;
  background-color: #dddddd;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  justify-content: space-around;
  flex-direction: column;
}

.sidebarHeader p {
  font-size: 10px;
  padding: 0px;
  margin: 0px;
}
.sidebar-search {
  width: 90%;
  height: 100%;
}

.sidebar-search input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.sidebar-search input:focus {
  border-color: var(--main-color);
  outline: none;
}

.draggable-node {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.2s;
}

.draggable-node:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.draggable-node-edit-btn {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: rgb(224, 224, 224);
  display: flex;
  color: rgb(108, 17, 226);
  justify-content: center;
  align-items: center;
}
.draggable-node-edit-btn:hover {
  background-color: rgb(70, 4, 114);
  color: rgb(255, 255, 255);
}

.sidebarBody {
  flex-grow: 1;
  max-height: calc(100% - 120px);
  overflow-y: scroll;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  justify-content: flex-start;
}

.sidebarBodyNewElementBtn:hover {
  background-color: var(--main-color);
  color: white;
  transition: background-color 0.3s, transform 0.2s;
  transform: scale(1.05);
}
.sidebarBodyNewElementBtn {
  width: 90%;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid #d4d4d4;
  cursor: pointer;
}
.sidebarFooter {
  height: 60px;
  background-color: #dddddd;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
}
/* Dünner und moderner Scrollbalken */
.sidebarBody::-webkit-scrollbar {
  width: 5px;
}

.sidebarBody::-webkit-scrollbar-track {
  background: #e3cffa;
  border-radius: 0px 0px 5px 5px;
}

.sidebarBody::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 5px 5px 5px 5px;
}

.sidebarBody::-webkit-scrollbar-thumb:hover {
  background: var(--main-color);
}

.draggable-node {
  width: 90%;
}

.sidebarFooter .saveBtn {
  height: 90%;
  width: 80%;
  background-color: var(--main-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s linear;
}
.sidebarFooter .saveBtn:hover {
  background-color: var(--main-color-hover);
  transform: scale(1.1);
}
