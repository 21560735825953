/*Aktivität*/
.ActivityNodeBox {
  width: 250px;
  height: 80px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--main-color);
  cursor: auto;
}

.ActivityNodeHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 2px 5px;
  border-radius: 4px 4px 0px 0px;
}

.ActivityLabel {
  font-size: 7px;
  width: 60%;
  font-weight: bold;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ActivityNodeHeader-Settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  font-size: 5px;
  gap: 5px;
}
.ActivityNodeBox .tHandle {
  border: none;
  width: 1px;
  height: 1px;
  z-index: -10;
  background-color: var(--main-color);
}
.nodeButton {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: center;
}
.nodeButton:hover {
  transform: scale(1.3);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.6);
  background-color: var(--main-color);
  color: #fff;
}

.ActivityNodeBody {
  padding: 5px;
}
.activityIcon {
  width: 10px;
  text-align: center;
}
.ActivityDesc {
  font-size: 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
}
.roleTag {
  width: 40px;
  height: 12px;
  margin-right: 3px;
  background-color: rgb(58, 140, 235);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  font-size: 5px;
  text-align: center;
}

.sourceConnection {
  background-image: linear-gradient(
    to left,
    rgba(94, 43, 255, 0.8),
    rgba(255, 255, 255, 1) 20px
  );
}

.targetConnection {
  background-image: linear-gradient(
    to right,
    rgba(94, 43, 255, 0.8),
    rgba(255, 255, 255, 1) 20px
  );
  animation: breatheChart 1.5s infinite alternate;
}

.targetUnplugConnection {
  background-image: linear-gradient(
    to right,
    rgba(245, 37, 9, 1),
    rgba(255, 255, 255, 1) 20px
  );
  border: 1px solid red;
  animation: breatheChart 1.5s infinite alternate;
}
.sourceUnplugConnection {
  background-image: linear-gradient(
    to left,
    rgba(245, 36, 9, 1),
    rgba(255, 255, 255, 1) 20px
  );
  border: 1px solid red;
}

.activityGroupNode {
  border: 1px solid rgb(54, 69, 79);
  border-radius: 5px;
}

.editGroup {
  border: 3px solid var(--main-color);
  animation: breatheChart 1.5s infinite alternate;
}

@keyframes breatheChart {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}
